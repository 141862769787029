body {
  margin: 0;
  font-family: 'Open Sans',
    sans-serif;
  background-color: #EDEDED;
}

code {
  font-family: 'Open Sans', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
