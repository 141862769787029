.DateRangePickerInput__withBorder{
    border-radius: 0px;
    border: 0px;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    margin: 4px 5px;
}
.DateInput__small {
    width: 60px;
}
.DateInput_input__small{
    font-size: 16px;
    font-family: Open Sans;
    line-height: 18px;
    letter-spacing: .2px;
    padding: 7px 7px 3px;
    color: black;
    text-align: center;
}
.DateRangePickerInput__withBorder:hover{
    border-bottom: 2px solid black;
}
.DateInput_input__focused{
    border-bottom: 2px solid #3f51b5;
}
.DateInput_input::placeholder{
    font-size: 12px;
}
button.DayPickerKeyboardShortcuts_buttonReset { display:none; }