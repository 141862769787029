.Overlay{
  background: rgba(0,0,0,0.4);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items:center;
}

.Modal {
    padding: 30px 80px 20px 20px;
    background: #FFFFFF;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
  }

.Modal_size_reports{
    min-width: 870px;
    min-height: 350px;
    max-width: 870px;
}
  
.Modal__close {
  font-size: 3em;
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: 20px;
  right: 30px;
  cursor: pointer;
  border-radius: 50%;
  padding-left: -40px;
  color: #AFAFAF;
  transform: rotate(45deg);
  transition: all 1s;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index:9999;
}

.Modal__close:hover {
  /* background-color: rgba(0, 0, 0, 0.2); */
  color: rgba(0, 0, 0, 0.6);

}

.Modal__title{
  font-family: Open Sans;
  font-weight: 300;
  font-style: normal;
  font-size: 22px;
  line-height: 19px;
  color: #0b1f82;
}

.Modal__body {
  border-radius: 5px;
  display: flex;
}

.Modal_body_reports{
  display: flex;
  flex-direction: column;
} 

.Modal_body_queryByName{
  display: flex;
  flex-direction: column;
} 

.Modal_button{
  position: absolute;
  bottom: 10px;
  left: 40%
} 

@media (max-width: 400px) {
  .Modal {
    padding: 15px 70px 15px 15px;
  }
}