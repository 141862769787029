.leaflet-container.crStore {
    height: 300px;
    width: calc(100% + 42px);
    margin-left:-21px;
    margin-right:-21px
  }

.leaflet-container.ciAlertMerchant {
  height: 262px;
  margin: -21px -21px -21px 0px
}

.leaflet-container.ciTransactionMerchant {
    height: 192px;
    margin-left:-21px;
    margin-right:-21px
  }

.leaflet-container.ciAlertHistoric {
    height: 220px;
    margin-left:-21px;
    width: calc(100% + 42px)
}