/* Layout  Debugging*/
/* * {
border: 1px solid red;
} */

/* teste cores hexadecimal */
/* .bgColor {
   background-color: #ffffff;
} */

/* Text Classes */


$qi-dark-blue-main-color: #0b1f82;
$qi-dark-blue-main-color-lighter: #8791c2;
$qi-dark-blue-main-color-darker: #020f4d;
$gray-color: #a5a5a5;
$gray-color-2: #d6d6d6;
$light-gray-color: #f1f2f7;
$lighter-gray-color: #f9fafc;
$light-yellow-green-color: #fbfbfb;

// Scrollbar
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    background: $light-gray-color;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #c7c7c7;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: $gray-color;
}

.normalText {
    font-family: Open Sans;
    font-style: normal
}
.dropDownTextFieldText {
    font-family: Open Sans;
    font-style: normal;
    line-height: 14px;
}
.normalTextWhite {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    color: #FFFFFF
}
.normalText.disabled {
    color: #BBB;
}
.blueText {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    color: $qi-dark-blue-main-color;
}
.yellowText {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    color: #F5A718;
}
.blueTextSearchAny {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    color: #174560;
}
.redText {
    font-family: Open Sans;
    font-weight: 600;
    font-style: normal;
    color: #D91A1A;
}
.blueTextLabel {
    font-family: Open Sans;
    font-style: normal;
    color: $qi-dark-blue-main-color;
}
.labelText {
    font-family: Open Sans;
    font-style: normal;
    color: #6F6F6F;
}
.tooltipText {
    font-family: Open Sans;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
}
.labelText.disabled {
    color: #999;
}
.moneySize {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}
.mediumMoneySize {
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
}
.reducedMoneySize {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.titleSize2 {
    font-size: 24px;
    line-height: 26px;
}

.titleSize {
    font-size: 20px;
    line-height: 24px;
}

.scoreSize {
    font-size: 20px;
    line-height: 27.24px;
}

.subtitleSize {
    font-size: 16px;
    line-height: 20px;
}

.dialogInfoSize {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
}

.normalMediumSize3{
    font-size: 20px;
    line-height: 22px;
    margin: 0px;
}

.normalMediumSize2{
    font-size: 14px;
    line-height: 16px;
    margin: 0px;
}
.normalMediumSize{
    font-size: 14px;
    line-height: 19px;
}
.normalSmallSize{
    font-size: 12px;
    line-height: 16px;
}
.normalTinySize{
    font-size: 11px;
    line-height: 12px;
}
.link {
    color: $qi-dark-blue-main-color;
    width: auto;
    cursor: pointer;
    text-decoration: none;
}
.link:hover {
    text-decoration: underline
}
.linkRed {
    color: #D91A1A;
    width: auto;
    cursor: pointer;
    text-decoration: none;
}
.labelSpace {
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

.bold {
    font-weight: 600;
}
.textOverflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
}
.textOverflow2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 130px;
}
.textOverflow3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}
.idOverflow {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip; 
    max-width: 63px; 
}
.textOverflowInbox {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

/* Container Classes */
.cardContainer {
    width: 100%;
    display: block;
}
.internalCollapse {
    margin: 20px;
}
.internalCollapse.oneColumn {
    margin: 20px;
}
.internalCollapse.twoColumn {
    column-count: 2;
    padding-right: 200px
}
.internalCollapse.threeColumn {
    column-count: 3
}
.emptyContainer {
    text-align :center;
    padding: 22px;
    background: white;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.circularProgressCenter {
    text-align :center;
    margin: auto;
}

.analysisCardContainer {
    margin: 10px;
    display: flex;
}
.smallerInternalCardContainer {
    margin: 8px 0
}
.internalCardContainer {
    margin: 15px 0
}
.decisionCardContainer {
    background-color:#e2d9d9;
    border-radius: 20px;
    padding: 20px;
}

.emptyPicture {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    display: flex;
    background: #e2d9d9;
    align-items: center;
    padding: 10px;
}
.arrowPicture {
    margin: 20px 0;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer
}
.arrowPicture:hover {
    background: #ddddf9;
}
.sectionContainer {
    border-radius: 4px;
    padding: 16px;
    margin: 10px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.internalSectionContainer {
    border: 1px solid #d8dce8;
    border-radius: 4px;
    padding: 4px 16px;
    margin: 2px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
}

/* Switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* Card Classes */
.analysisCardNoFlex {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 21px;
}

.analysisCard {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #cccc;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
}

.analysisCardType2 {
    width: 100%;
    background: #FFFFFF;
    border-color: rgba(0, 0, 0, 0.106);
    border-width: 1px;
    border-style: groove;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 21px;
    display:flex;
    flex-grow: 1;
    flex-direction: column;
}

.analysisCardSummaryLargeSize {
    font-size: 30px;
    line-height: 40px;
}
.analysisCardSummaryColor {
    color:#6F6F6F;
}
.alignCenter {
    text-align: center;
}

.listCardHeader {
    padding: 0px 10px 0px 10px;
    margin: 0px;
    display:flex;
    color:#6F6F6F
}
.listCard {
    padding: 10px 15px;
    margin: 10px 0px 10px 0px;
    display:flex;
    align-content: space-evenly;
}
.listCard:hover {
    background: #F3F3F3;
}
.listCard.collapse {
    flex-direction: column;
}
.listCardItem {
    flex-grow: 0;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.listCard2 {
    background:white;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    border-radius:4px;
    padding: 10px 15px;
    margin: 10px 0px 10px 0px;
    display:flex;
    align-content: space-evenly;
    flex-direction: column;
}
.observationCard {
    background: #F7F7F7;
    border-radius: 10px;
}
.timelineCard{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 17px 16px;

}
.timelineCard.blueBorder{
    border: 2px solid #3191C7;
}
/* filter Classes */
.filterBoxContainer {
    width: auto;
    display: flex;
}
.filterBox {
    float:left;
    margin: 2px 4px;
    background: white;
    border-radius: 8px;
    box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12);
    max-height: 48px;
}

.filterBox div{
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 600;
}

.selectedFilter {
    margin: 10px;
    width: 130px;
}
.selectedOrderBy {
    margin: 10px;
    width: 180px;
}
.filterContainer {
    background: white;
    padding: 4px 15px;
    display: flex;
    width: 310px;
    align-items: center;
    border-radius: 4px;
    float: left;
    margin: 2px 4px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 2px 0px rgba(0,0,0,0.12);
}

.filterContainer input{
    font-family: Open Sans;
    font-size: 12px;
}

.filterContainer em{
    font-family: Open Sans;
    font-size: 12px;
}

.filterContainerError {
    background: white;
    border-style: solid;
    border-width: 2px;
    border-color: red;
    padding: 4px 15px;
    display: flex;
    width: 310px;
    align-items: center;
    border-radius: 4px;
    float: left;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.filterDescription {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #141414;
    margin: 4px 5px;
    float: left;
    min-width: 80px;
    max-width: 100px;
}
.filterTextbox {
    width: 150px;
    margin: 4px 5px;
    font-family: Open Sans;
    font-size: 12px
}
.filterCurrencyCredit {
    width: 130px;
    height: 30px;
    border: 0;
    border-bottom: 1px solid #AFAFAF;
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.01071em;
}
.filterCurrencyCredit:hover{
    outline: none;
    border-bottom: 2px solid black;
}
.filterCurrencyCredit:focus{
    outline: none;
    border-bottom: 2px solid $qi-dark-blue-main-color;
    color: rgba(0, 0, 0, 0.87);
}

.filterCurrencyCreditError {
    width: 130px;
    height: 30px;
    border: 0;
    border-bottom: 1px solid #AFAFAF;
    color: rgba(0, 0, 0, 0.54);
    font-size: 18px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.01071em;
}
.filterCurrencyCreditError:hover{
    outline: none;
    border-bottom: 2px solid black;
}
.filterCurrencyCreditError:focus{
    outline: none;
    border-bottom: 2px solid red;
    color: rgba(0, 0, 0, 0.87);
}



.filterDescriptionCredit {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 200;
    font-size: 12px;
    line-height: 1.43;
    color: rgba(0, 0, 0, 0.54);
    margin: 4px 5px;
    float: left;
    border: 0;
    letter-spacing: 0.01071em;
}
.filterTextboxCurrency {
    width: 130px;
    height: 30px;
    margin: 4px 5px;
    border: 0;
    border-bottom: 1px solid #AFAFAF;
    font-family: Open Sans;
    font-size: 12px
}
.filterTextboxCurrency:hover{
    border-bottom: 2px solid black;
}
.filterTextboxCurrency:focus{
    outline: none;
    border-bottom: 2px solid blue;
}

.inputTextbox {
    margin: 4px 5px;
    font-family: Open Sans;
    font-size: 12px;
}
.inputCheckbox:checked{
    background-color: blue;
}

.boolTextbox {
    margin: 4px 5px;
    font-family: Open Sans;
    font-size: 12px;
    width: 100%;
}
/* General Classes */
.pagination {
    float: right
}
.breakWord {
    word-break: break-all;
}
.noWrap {
    white-space: nowrap;
}
.statusTag {
    border-radius: 30px;
    padding: 5px 10px;
    width: 100%;
    text-align: center;
}
.triggerNumberStyle {
    width: 40px;
    background-color: #c4c4c4;
    border-radius: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.centerText {
    text-align: center;
}

/* Color */
.blue {
    color: $qi-dark-blue-main-color;
}
.green {
    color: #297835;
}
.gray {
    color: #9e9b8e;
}
.lighterGrayBackground {
    background-color: #eeeeee;
}
.lighterGrayBorder {
    border: 1px solid #eeeeee;
}
.darkGray {
    color: #525252;
}
.backgroundWhite {
    background-color: white;
}

/* Border */
.border-1-blue {
    border: 1px solid $qi-dark-blue-main-color;
}
.border-1-transparent {
    border: 1px solid transparent;
}

/* Button Classes */

.button:hover {
    box-shadow: 0px 1px 1px 0px rgba(105, 105, 105, 0.5);
    transform: translateY(-1px);
    transition: all .2s ease;
}
.button {
    border-radius: 30px;
    padding: 5px 10px;
    text-align: center;
    cursor: pointer;
    margin: 0 10px
}
.button.approved {
    color: white;
    background-color: #73C580
}
.button.approved:hover{
    background-color: #63B570
}
.button.approved.disabled {
    color:#14942a;
    background-color: #73C580;
    cursor: default;
    border: 1px solid transparent !important;
    box-shadow: 0px 1px 1px 0px transparent !important;;
    transform: translateY(0px);
}
.button.reproved {
    color: white;
    background-color: #D91A1A
}
.button.reproved:hover{
    background-color: #C90A0A
}
.button.reproved.disabled {
    color: #dc7d7d;
    background-color: #D91A1A;
    cursor: default;
    border: 1px solid transparent !important;
    box-shadow: 0px 1px 1px 0px transparent !important;;
    transform: translateY(0px);
}
.button.challenged {
    color: white;
    background-color: #F5AF2F
}
.button.challenged:hover{
    background-color: #F5A718
}
.button.standard {
    color: white;
    background-color: $qi-dark-blue-main-color
}
.button.standard:hover{
    background-color: $qi-dark-blue-main-color-darker;
}
.button.standard.selected{
    background-color: $qi-dark-blue-main-color-darker;
}
.button.standard.disabled {
    background-color: $qi-dark-blue-main-color-lighter;
    cursor:not-allowed;
    transform: translateY(0px);
}
.button.standard.disabled.white {
    color: rgb(170, 167, 153);
    background-color: $qi-dark-blue-main-color;
    cursor:not-allowed;
}
.button.standard.disabled.gray {
    color: white;
    background-color: rgb(189, 189, 189);
    cursor:not-allowed;
}
.button.standard.disabled.blue {
    color: white;
    background-color: $qi-dark-blue-main-color-lighter;
    cursor:not-allowed;
}
.button.cancel {
    color: black;
    background-color: rgb(170, 167, 153)
}
.button.cancel:hover{
    background-color: rgb(157, 155, 145)
}
.button.onlyboarder {
    color: $qi-dark-blue-main-color;
    border: 2px solid $qi-dark-blue-main-color;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.12));
}
.button.onlyboarder.disabled {
    color: grey;
    border: 2px solid $qi-dark-blue-main-color;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.12));
    cursor: default
}
.button.clicked {
    color: $qi-dark-blue-main-color;
    border: 2px solid $qi-dark-blue-main-color;
    background-color: #dbeeff;
    box-sizing: border-box;
    filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.12));
}
.button.onlyboarder:hover{
    background-color: #f0f8ff
}
.buttonCard{
    border: 0px;
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
}
.buttonCard:hover{
    background: #F3F3F3;
    border-radius: 20px;
}
/* Icons Classes */
.entryIconStyle{
    width: 30px;
    height: 20px
}
.entryIconStyle g {
    fill: #6F6F6F;
}
.cardTitleIconStyle{
    min-width: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.cardTitleIconStyle g {
    fill: $qi-dark-blue-main-color;
}
.pinIconStyle {
    margin: auto 10px auto auto
}
.circle {
    width: 60px;   
    height: 60px;
    border-radius: 50%; 
    margin:auto;
    display:flex;
    justify-content: center;
    align-items: center;
}
.circle.medium {
    width: 45px;   
    height: 45px;
}
.circle.small {
    width: 25px;   
    height: 25px;
}
.circle.littleSmall {
    width: 18px;   
    height: 18px;
}
.circle.superSmall {
    width: 10px;   
    height: 10px;
}
.circle.big {
    width: 100px;   
    height: 100px;
}
.fraudStatusIcon{
    color: #ffffff;
    margin: auto;
}
.timelineIcon{
    margin: auto;
}
/* Credit Card Classes */
.greyCard {
    position: relative;
    margin: auto;
    width: 184px;
    height: 111px;
    background: linear-gradient(88.73deg, #848484 2.21%, #AFAFAF 99.34%);
    border-radius: 15px;    
}
.blackCard {
    position: relative;
    margin: auto;
    width: 283.2px;
    height: 170.84px;
    background: linear-gradient(89.24deg, #141414 2.21%, #333333 99.34%);
    border-radius: 15px;
}.brandStyleBlackCard {
    position: absolute;
    width: 35.69px;
    height: 42.06px;
    left: 18px;
    top: 47px;
}
.cardNumberStyleBlackCard {
    position: absolute;
    width: auto;
    left: 18px;
    top: 97.2px;
}
.validDateStyleBlackCard {
    position: absolute;
    left: 18px;
    top: 130px;
}
.brandStyle {
    position: absolute;
    width: 35.69px;
    height: 22.06px;
    left: 18px;
    top: 25px;
}
.decisionText{
    font-family: Open Sans;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
}
.centralize {
    display: flex;
    justify-content: center;
    align-items: center;
}
.cardTypeStyle {
    position: absolute;
    transform: translate(-50%, 0); 
    left: 130px;
    top: 80px;
}
.cardNumberStyle {
    position: absolute;
    width: auto;
    left: 18px;
    top: 55px;
}
.validDateStyle {
    position: absolute;
    left: 18px;
    top: 80px;
}
/* Table Classes */
.even {
    background-color: #F3F3F3
}
.odd {
    background-color: #FFFFFF
}
.currency {
    background-color: #F7F7F7;
    border-width: 1px;
    border-color: #AFAFAF; 
    border-style: solid; 
    padding: 5px;
    border-radius:10px;
    outline: none;
}
.currency.select:hover{
    border-color: #141414; 
    border-width: 1px; 
}
.currency.select:focus{
    border-width: 1.8px; 
    border-color:$qi-dark-blue-main-color;
    outline: none;
}
.currency.select:focus:hover{
    border-width: 1.8px; 
    border-color:$qi-dark-blue-main-color;
    outline: none;
}
.listCardPlusContainer{
    width:163px;
    position: fixed;
    height:140px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    right: 0;
    background-color: white;
    display:flex;
    flex-direction:column;
    z-index: 2;
}

.cardList {
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    z-index: 2;
}

.listCardPlusOptions{
    display:flex;
    flex:1 0 5px;
    cursor: pointer;
}
.listCardPlusOptions:hover{
    display:flex;
    flex:1 0 5px;
    background-color: #f5f5f5;
}

@media print {
    html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }
    .avoidPageBreakInside {
        page-break-inside: avoid;
    }
    .pagebreak {
        page-break-before: always;
    }
}

.SCRtableContainer_width {
    margin: 10px;
    width: 95%;
}

.SCRtableContainer_width_pdf {
    margin: 10px;
    width: 850px;
}

.SCRtableContainer {
    display: flex;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2); 
    border-radius: 10px; 
    padding: 10px; 
}

.formLabelFieldText {
    display: block;
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    margin: 0px;
    padding: 0px;
}


.formControl input,
.formControl label {
    display: block;
    font-size: 14px;
    line-height: 7px;
}

.formControl input,
.formControl select {
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 26rem;
    max-width: 100%;
}

.formControl input:focus {
    outline: none;
    border-color: $qi-dark-blue-main-color;
    background-color: #e8f3f9;
}

.formControl input {
    font-weight: 400;
    padding: 0.4rem;
    background-color: rgb(248, 248, 248);
}

.formControl label {
    font-weight: 600;
}

.invalid input {
    border: 1px solid #b40e0e;
    background-color: #fceeee;
}

.invalid input:focus {
    border-color: #b40e0e;
    background-color:  #fbdddd;
}

.normalSmallSize__error{
    color: #b40e0e;
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 0px;
}

.formVerySmallWhiteSpace {
    min-height: 6px;
}

.formSmallWhiteSpace {
    min-height: 12px;
}

.formMediumWhiteSpace {
    min-height: 18px;
}

.formLargeWhiteSpace {
    min-height: 24px;
}

.formBlueLine {
    border: 2px solid $qi-dark-blue-main-color;
    background-color:  $qi-dark-blue-main-color;
}

.formTitleText {
    font-family: Open Sans;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 7px;
    margin-top: 0px;
    padding-top: 12px;
    padding-bottom: 0px;
}

.dropdown select {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 7px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 26rem;
    max-width: 100%;
    font-weight: 400;
    padding: 0.4rem;
}

.dropdown label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    line-height: 7px;
}

.dropdown input:focus {
    outline: none;
    border-color: $qi-dark-blue-main-color;
    background-color: #e8f3f9;
}

.dropdown label {
    font-weight: 600;
}

.multiSelectDropdown{
    position:relative;
    width: 90%;
}
.multiSelectDropdownSearch{
    position:relative;
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    background-color: #eaeef0 !important;
}
.multiSelectDropdownSearch.input{
    border: none;
    background-color: transparent !important;
    border-radius: 4px;
    padding: 10px 15px 10px 6px;
}
.multiSelectDropdownSearch.input:focus-visible {
    outline: none;
}

.multiSelectDropdownSearch.field{
    border-radius: 4px;
    padding: 0px;
    margin: 1px auto;
    border: 1px solid rgb(184, 184, 184);
}
.multiSelectDropdownSearch:hover{
    background-color: #d8dfe4 !important;
}
.singleSelectDropdownSelected{
    position:relative;
    width:100%;
    border-radius: 4px;
    border: 1px solid rgb(184, 184, 184);
    padding: 10px 15px;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(248, 248, 248) !important;
    margin: 1px auto;
}
.multiSelectDropdownSelected{
    position:relative;
    width:100%;
    border-radius: 4px;
    border: 1px solid rgb(184, 184, 184);
    padding: 10px 15px;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(248, 248, 248) !important;
    margin: 1px auto;
}
.multiSelectDropdownOptions{
    width:100%;
    position: relative;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgb(184, 184, 184);
    left:0;
    list-style: none;
    padding:5px 0px;
    margin: 0px auto;
    max-height: 200px;
    overflow-y: scroll;
}
.multiSelectDropdownOption{
    display:flex;
    align-items: center;
    padding:6px 10px;
    background-color: white;
}
.multiSelectDropdownOption:not([disabled]):hover{
    background-color: #eee;
    cursor:pointer;
}
.multiSelectDropdownOption[disabled]:hover{
    cursor:not-allowed;
}
.multiSelectDropdownCheckbox{
    border: none;  
    border-radius: 4px;
    border: 1px solid rgb(184, 184, 184);
    margin-right:6px;
    min-width:18px;
    min-height: 18px;
}
.dialogTitleWithIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filterButton{
    margin: 4px;
    height: auto;
    display: flex;
    align-content: center;
    width: 150px;
    height: 25px;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    background-color: white;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}
.filterButtonSelected {
    background-color: $light-gray-color;
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
}
.filterButton:hover{
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
}
.groupsButtonContainer {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 5px 0 5px 10px;
}

.userRolesManagementSubtitle {
    display: flex;
    flex-direction: column;
}

.userRolesManagementContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.userRolesManagementRowContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.filter {
    display: flex;
    flex-direction: column;
    gap: 2px;
    flex: 1;
}
.flexColumnContainer{
    display: flex;
    flex-direction: column;
}

.flexRowContainer{
    display: flex;
    flex-direction: row;
}

.filterLabelContainer {
    display: flex;
    flex-direction: row;
    gap: 2px;
    align-items: center;
}

.filter input,select {
    outline: none;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: white;
    padding: 10px 5px;
    width: 100%;
}

.filter select {
    cursor: pointer;
}

.filter input:focus,select:focus {
    border: 1px solid #525252;
}

.ruleEngineButton:hover{
    border: 1px solid $qi-dark-blue-main-color;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.5);
    transform: translateY(-1px);
}

.ruleEngineButton {
    border-radius: 4px;
    padding: 10px 5px;
    text-align: center;
    cursor: pointer;
    margin: 0;
    width: 100%;
}

.ruleEngineButton.whiteBlue {
    background-color: white;
    border: 1px solid $qi-dark-blue-main-color;
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e4f3f7;
    border: 1px solid #cccccc;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 8px;
}

.dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e4f3f7;
    border: 1px solid #cccccc;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    border-radius: 8px;
}

.dialogContent {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
}
.dialogContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.variableCell {
    flex: 1;
    padding: 5px;
}

.labelInput {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px;
}

.labelInput #error {
    border: 1px solid #b82c2c;
}

.labelInput input {
    padding: 10px 5px;
    border: 1px solid #cccccc;
    border-radius: 4px;
}

.labelInput input:focus {
    outline: none;
    border: 1px solid #525252;
}

.labelInput input[disabled] {
    cursor:not-allowed;
    background-color: #eeeeee;
}

button [disabled] {
    opacity: 50%;
    cursor: not-allowed;
}

.iconDisabled {
    opacity: 50%;
    cursor: not-allowed;
}

.noMargin {
    margin: 0;
}

.noPadding {
    padding: 0;
}

.coloredStatus {
    margin: auto;
    border-radius: 4px;
    padding: 4px 8px;
    white-space:  nowrap;
    text-align: center;
}

.marginTopBottom2 {
    margin-top: 2px;
    margin-bottom: 2px;
}

.marginLeft15 {
    margin-left: 15px;
}

.buttonRowWrapper {
    display:flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
}

.topSecondaryBar {
    background-color: $light-gray-color;
    padding: 0px 40px;
    gap: 8px;
    display: flex;
    flex-direction: row;
    height: calc(20px + 4vh);
}

.topSecondaryBarItem {
    font-weight: bold;
    color: $gray-color;
    padding: 1vh 20px;
    margin: 1vh 0px;
    border-radius: 8px;
    letter-spacing: 1px;
    height: calc(20px + 2vh);
}


.topSecondaryBarTextSelection {
    color: $qi-dark-blue-main-color;
    position: relative;
    display: inline-block;
}

.topSecondaryBarTextSelection::after {
    content: "";
    position: absolute;
    transform: translate(-95%, 20px);
    width: 100%;
    height: 5px;
    background-color: #4fcced;
}

.topSecondaryBarItem:hover {
    color: $qi-dark-blue-main-color;
    cursor: pointer;
    transition: color 1s;
}

.mainPageBorder {
    min-height: calc(100vh - 20px - 4vh);
    width: 100%;
    height: 100%;
    padding: 0px 1.5% 1.5% 1.5%;
    background-color: $light-gray-color;
}

.mainPage {
    min-height: calc(100vh - 20px - 7vh);
    height: fit-content;
    background-color: $light-yellow-green-color;
    border-radius: 8px;
    padding: 1.5%;
    border: solid 1px $gray-color-2;
}

.languageSelectorContainer {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 5px 20px;
}

.sidebar {
    background-color: $light-gray-color;
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
}

.sidebarLogo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $light-gray-color;
}

.sidebarItemsContainer {
    width: 97%;
    font-weight: bold;
    margin: 0px 1% 0px 2%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d6d6d6;
    gap: 4px;
}

/* Sidebar links */
.sidebarItems {
    color: $gray-color;
    display: flex;
    padding: 12px 10px 12px 5px;
    text-decoration: none;
    border-left: $light-gray-color solid 5px;
    border-radius: 8px;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    text-decoration: none !important;
    cursor: pointer;
}

.collapsibleItem {
    color: $gray-color;
    display: flex;
    padding: 4px 8px;
    margin-bottom: 8px;
    text-decoration: none;
    border-left: $light-gray-color solid 5px;
    border-radius: 8px;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
    border-radius: 4px;
    gap: 8px;
    text-decoration: none !important;
    cursor: pointer;
    width: 100%;
    justify-content: center;
}

/* Active link */
.sidebarItems.active {
    color: $qi-dark-blue-main-color;
    font-weight: bold;
    border-left: #4fcced solid 5px;
    background-color: #ffffff;
    box-shadow: .5px .5px .5px .5px rgba(105, 105, 105, 0.5);
}

/* Hover effect */
.sidebarItems:hover {
    border-left: #bde2ec solid 5px;
    background-color: #ffffff;
    box-shadow: .5px .5px .5px .5px rgba(105, 105, 105, 0.5);
    transition: border-left 2s, background-color 2s, box-shadow 4s;
}

.searchAny {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    margin: 10px 0;
    border: 1px solid rgb(184, 184, 184);
    border-radius: 8px;
    gap: 5px;
}
.searchAny input{
    border: none;
    background-color: transparent;
    border-radius: 4px;
    padding: 10px 15px 10px 6px;
    width: 100%;
    height: 44px;
}
.searchAny input:focus-visible {
    outline: none;
}
.searchAny:hover{
    background-color: #f7f8f8;
    cursor: pointer;
}

.listIcon {
    color: $gray-color;
    font-size: 19px !important;
}
.listIcon.active {
    color: $qi-dark-blue-main-color;
    font-size: 19px;
}

.ruleEngineSidebar {
    display: flex;
    flex-direction: column;
    padding: 1%;
    height: 100%;
    width: 20%;
    background-color: $light-gray-color;
    border-radius: 8px;
    gap: 10px;
    border: solid 1px $gray-color-2;
}

.ruleEnginePage{
    display: flex;
    height: calc(100vh - 20px - 12vh);
    margin: 0;
}

.justifyText {
    text-align: justify;
}

.internalCollapse {
    display: flex;
    flex-wrap: wrap;
}
  
.twoColumn {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; 
}
  
.column {
    flex: 1 1 45%; 
    margin-bottom: 2px; 
}